import axios from 'axios';

const API = 'bibliotecas-carpetas';

const ENDPOINTS = {

    getListaCarpetas(){
        return axios.get(`${API}/lista`)
    },
    getAgrupaciones(){
        return axios.get(`${API}/agrupaciones-viviendas`)
    },
    getviviendasAcceso(idCarpeta){
        return axios.get(`${API}/${idCarpeta}/viviendas-acceso`)
    },
    postViviendasAcceso(payload){
        return axios.post(`${API}/viviendas-acceso`, payload)
    },
    getViviendas(idAgrupacion, params = null){
        return axios.get(`${API}/agrupaciones/${idAgrupacion}/viviendas-list`, {params})
    },
    editarNombreCarpeta(payload){
        return axios.put(`${API}/edit-nombre`, payload)
    },
    editarPrivacidadCarpeta(payload){
        return axios.put(`${API}/editar-tipo`, payload)
    },
    crearBiblioteca(payload){
        return axios.post(`${API}/guardar`, payload)
    },
    eliminarCarpeta(idCarpeta){
        return axios.delete(`${API}/${idCarpeta}/eliminar`)
    }

};

export default ENDPOINTS;