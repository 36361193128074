<template>
    <section class="mx-4 mt-4">
        <div class="row mx-0 pl-3 mt-3 mb-5">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'biblioteca.carpetas' }">
                    Biblioteca
                </el-breadcrumb-item>
                <el-breadcrumb-item>Nueva carpeta</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="row mx-0">
            <div class="col-12" />
            <div class="col-6">
                <ValidationObserver ref="formPrincipal" class="w-100">
                    <p class="col-auto f-20 f-500 mb-3">Nueva carpeta</p>
                    <div class="row mx-0 mb-4">
                        <div class="col-12 px-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:60" name="Nombre">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Nombre
                                </p>
                                <el-input v-model="nombre" placeholder="Nombre" class="w-50" size="small" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 ">
                        <div class="col-8 pl-0 pr-3 pl-0">
                            <p class="f-14 text-black">
                                Define la privacidad de la carpeta.
                            </p>
                            <ValidationProvider v-slot="{ errors }" rules="required" name="privacidad" tag="div">
                                <div class=" f-14 mt-1 my-auto">
                                    <label class="d-middle mr-3" for="">
                                        <input v-model="checked" :value="11" type="radio"
                                               class="option-input opt-gris radio mr-2"
                                        />
                                        <div class=" text-black f-18">
                                            <i class="icon-lock f-15 text-black mx-2" />
                                            Privado
                                        </div>
                                    </label>
                                    <p class="f-14 mx-4 mb-2">Solo tu puedes ver esta carpeta y sus archivos</p>
                                    <label class="d-middle mr-3 mt-3" for="">
                                        <div class=" text-black f-18 mx-3">
                                            <i class="icon-group-outline f-15 text-black mx-2" />
                                            Público
                                        </div>
                                    </label>
                                    <p class="f-14 mx-4 mb-2">
                                        Todos los residentes de este conjunto pueden ver la
                                        carpeta y sus archivos
                                    </p>
                                    <div class="mx-4 row">
                                        <input v-model="checked" :value="21" type="radio" class="option-input opt-gris radio mr-2" /> Residentes y propietarios
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="23" type="radio" class="option-input opt-gris radio mr-2" /> Solo residentes
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="22" type="radio" class="option-input opt-gris radio mr-2" /> Solo propietarios
                                    </div>
                                    <label class="d-middle mr-3" for="">
                                        <div class=" text-black f-18 mx-3 mt-3">
                                            <i class="icon-account-plus f-15 text-black mx-2" />
                                            Compartir
                                        </div>
                                    </label>
                                    <p class="f-14 mx-4 mb-2">
                                        Solo las viviendas o agrupaciones seleccionadas pueden ver
                                        la carpeta y sus archivos
                                    </p>
                                    <div class="mx-4 row">
                                        <input v-model="checked" :value="31" type="radio" class="option-input opt-gris radio mr-2" /> Residentes y propietarios
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="33" type="radio" class="option-input opt-gris radio mr-2" /> Solo residentes
                                    </div>
                                    <div class="mx-4 row mt-2">
                                        <input v-model="checked" :value="32" type="radio" class="option-input opt-gris radio mr-2" /> Solo propietarios
                                    </div>
                                </div>
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                </validationobserver>
            </div>
        </div>
        <div class="row mx-0 mt-5 pl-4">
            <div class="col-auto px-0">
                <button type="button" class="btn-general btn btn-sm px-4" @click="crearBiblioteca">
                    Guardar
                </button>
            </div>
        </div>
    </section>
</template>


<script>
import moment from 'moment'
import Carpetas from '~/services/bibliotecas/bibliotecas-carpetas'
export default {
    data(){
        return {
            checked: 11,
            nombre: '',
            agrupaciones: [],
            prueba: ''
        }
    },
    methods: {
        async crearBiblioteca(){
            try {

                let validate = await this.$refs.formPrincipal.validate()
                if (!validate) return this.notificacion('', 'Por favor revisa todos los campos')
                let viviendas = []
                const payload = {
                    tipo: this.checked,
                    nombre: this.nombre,
                    viviendas
                }
                const { data } = await Carpetas.crearBiblioteca(payload)
                this.notificacion('Exito', data.mensaje, 'success')

                if (this.checked > 30){
                    this.$router.push({ name: 'biblioteca.carpetas.editar.privacidad', params: { idCarpeta: data.idCarpeta, privacidad: this.checked } })
                }else{
                    this.$router.push({ name: 'biblioteca.carpetas' })
                }
            } catch (error){
                return this.errorCatch(error);
            }
        }

    }
}


</script>
<style lang="scss" scoped>
.cropper-style {
	height: 300px;
	width: 300px;
	background-color: #f1f1f1;
	color: #868686;
	border-radius: 8px;
}

.box-ventana {
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #DFE4E8;
	border-radius: 4px;
}

.label {
	color: #5F6D84;
	font-size: 12px;
	padding-left: 15px;
}
</style>